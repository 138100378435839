import React from 'react';
import Layout from '../components/Layout';
import Features from '../components/Features';

const FeaturesPage = () => (
  <Layout>
    <Features />
  </Layout>
);

export default FeaturesPage;
