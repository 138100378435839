import React from 'react';
import styled from 'styled-components';
import theme from '../theme';
import { Button } from '@material-ui/core';

const Table = styled.table`
  margin-top: 50px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
  border-bottom: 1px solid ${theme.palette.blue.main};
  overflow: hidden;
  color: ${theme.palette.blue.main};

  strong {
    font-weight: 600;
  }

  tr {
    th {
      padding: 5px;
      padding-left: 15px;
      text-align: left;
      font-size: 15px;
      width: 100%;
      background-color: ${theme.palette.blue.main};
      font-family: Roboto;
      color: white;
      font-weight: normal;
    }

    td {
      border-left: 1px solid ${theme.palette.blue.main};
      text-align: center;
      font-family: Roboto;
      font-size: 15px;
      padding: 15px;
    }
  }

  tr.with-border {
    td {
      &:last-child {
        border-right: 1px solid ${theme.palette.blue.main};
      }
    }
  }

  tr.no-border {
    td {
      border: none;
    }
  }

  td.free {
    font-weight: 500;
  }

  .row-title {
    /* max-width: 100px; */
  }
`;

class Features extends React.PureComponent {
  public render() {
    return (
      <div>
        <Table cellSpacing={0}>
          <tr className="no-border" style={{ verticalAlign: 'bottom' }}>
            <td />
            <td>
              <Button color="primary" variant="contained">
                Créer gratuitement votre Campus
              </Button>
            </td>
            <td>
              <Button
                style={{
                  backgroundColor: theme.palette.green.main,
                  color: theme.palette.common.white,
                }}
                variant="contained"
              >
                Nous contacter
              </Button>
            </td>
            <td>
              <Button
                style={{
                  backgroundColor: theme.palette.green.main,
                  color: theme.palette.common.white,
                }}
                variant="contained"
              >
                Nous contacter
              </Button>
            </td>
            <td>
              <Button
                style={{
                  backgroundColor: theme.palette.green.main,
                  color: theme.palette.common.white,
                }}
                variant="contained"
              >
                Nous contacter
              </Button>
            </td>
          </tr>
          <tr>
            <th colSpan={5}>
              <strong>Fonctionnalités</strong> - Choisissez celles que vous
              souhaitez pour votre Campus
            </th>
          </tr>
          <tr className="with-border">
            <td className="row-title">Boite</td>
            <td className="free">Gratuit</td>
            <td>
              <i className="fas fa-check-circle" />
            </td>
            <td>150 € par mois</td>
            <td rowSpan={7} style={{ maxWidth: '100px' }}>
              Concevez une solution sur-mesure pour votre entreprise avec nos
              experts en innovation
            </td>
          </tr>
          <tr>
            <td className="row-title">Lab</td>
            <td>-</td>
            <td>150 € par mois</td>
            <td>300 € par mois</td>
          </tr>
          <tr>
            <td className="row-title">Intrapeunariat</td>
            <td>-</td>
            <td>150 € par mois</td>
            <td>300 € par mois</td>
          </tr>
          <tr>
            <td className="row-title">Open innovation</td>
            <td className="free">Gratuit</td>
            <td>
              <i className="fas fa-check-circle" />
            </td>
            <td>300 € par mois</td>
          </tr>
          <tr>
            <td className="row-title">Hackathon</td>
            <td>-</td>
            <td>150 € par mois</td>
            <td>300 € par mois</td>
          </tr>
          <tr>
            <td className="row-title">Academie</td>
            <td className="free">Gratuit</td>
            <td>
              <i className="fas fa-check-circle" />
            </td>
            <td>150 € par mois</td>
          </tr>
          <tr>
            <td className="row-title">Tableau de bord</td>
            <td>-</td>
            <td>50 € par mois</td>
            <td>50 € par mois</td>
          </tr>
          <tr>
            <th colSpan={5}>
              <strong>Support</strong>
            </th>
          </tr>
          <tr className="with-border">
            <td className="row-title">Support par mail</td>
            <td>
              <i className="fas fa-check-circle" />
            </td>
            <td>
              <i className="fas fa-check-circle" />
            </td>
            <td>
              <i className="fas fa-check-circle" />
            </td>
            <td>
              <i className="fas fa-check-circle" />
            </td>
          </tr>
          <tr className="with-border">
            <td className="row-title">Support par telephone</td>
            <td>-</td>
            <td>-</td>
            <td>
              <i className="fas fa-check-circle" />
            </td>
            <td>
              <i className="fas fa-check-circle" />
            </td>
          </tr>
          <tr>
            <th colSpan={5}>
              <strong>Coaching & Formations</strong>
            </th>
          </tr>
          <tr className="with-border">
            <td className="row-title">Coaching personnalisé</td>
            <td>sur-devis</td>
            <td>sur-devis</td>
            <td>sur-devis</td>
            <td>
              <i className="fas fa-check-circle" />
            </td>
          </tr>
          <tr className="with-border">
            <td className="row-title">Formations</td>
            <td>sur-devis</td>
            <td>sur-devis</td>
            <td>sur-devis</td>
            <td>
              <i className="fas fa-check-circle" />
            </td>
          </tr>
        </Table>
      </div>
    );
  }
}

export default Features;
